import React from 'react';
import { Link } from 'gatsby';

export interface PostLinkProps {
  slug: string;
  date: string;
  title: string;
}

const PostLink = (props: PostLinkProps): React.ReactElement => {
  const { slug, date, title } = props;

  return (
    <div>
      <li>
        {date} - <Link to={slug}>{title}</Link>
      </li>
    </div>
  );
};

export default PostLink;
