import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout/Layout';
import { PostLinkProps } from '../components/PostLink/PostLink';
import PostList from '../components/PostList/PostList';
import SEO from '../components/SEO/SEO';

interface PostFrontmatter {
  title: string;
  date: string;
}

interface PostInjectedFields {
  slug: string;
}

interface Post {
  node: {
    id: string;
    frontmatter: PostFrontmatter;
    fields: PostInjectedFields;
  };
}

interface AllPostsQuery {
  allMarkdownRemark: {
    edges: Post[];
  };
}

interface IndexPageProps {
  data: AllPostsQuery;
}

const IndexPage = ({ data }: IndexPageProps): React.ReactElement => {
  let posts: PostLinkProps[] = [];
  if (data.allMarkdownRemark) {
    posts = data.allMarkdownRemark.edges.map(
      (post): PostLinkProps => {
        return {
          title: post.node.frontmatter.title,
          date: post.node.frontmatter.date,
          slug: post.node.fields.slug
        };
      }
    );
  }

  return (
    <Layout>
      <SEO title="Home" />
      <PostList posts={posts} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { displayHomePage: { eq: true } } }
      sort: { fields: [frontmatter___date, frontmatter___title], order: [DESC, ASC] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default IndexPage;
