import React from 'react';
import PostLink, { PostLinkProps } from '../PostLink/PostLink';

export interface PostListProps {
  posts: PostLinkProps[];
}

const PostList = (props: PostListProps): React.ReactElement => {
  const { posts } = props;
  const postLinks = posts.map(
    (post: PostLinkProps): React.ReactElement => {
      return <PostLink key={post.slug} slug={post.slug} title={post.title} date={post.date} />;
    }
  );

  return (
    <div>
      <ul>{postLinks}</ul>
    </div>
  );
};

export default PostList;
